.hero {
	background-color: $color-blue;
	position: relative;
	z-index: 0;
	text-align: center;

	&::after {
		content: "";
		display: block;
		width: 55%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 50;
		background: linear-gradient(90deg, rgba(0, 95, 168, 1) 0%, rgba(0, 95, 168, 1) 50%, rgba(0, 95, 168, 0.8) 62%, rgba(0, 0, 0, 0) 100%);

	}

	@media (max-width: $breakPoint769) {
		position: relative;
		padding: 0;
		&::after {
			content: "";
			width: 100%;
			height: 100%;
			display: block;
			position: absolute;
			z-index: 50;
		}
	}

	h1 {
		font-size: $fontsize-41;

		&:last-child {
			margin-bottom: 0;
		}

		@media (max-width: $breakPoint481) {
			font-size: $fontsize-32;
		}
	}

	&__text__outer {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 100;
		text-align: left;

		@media (max-width: $breakPoint769) {
			position: static;
		}
	}

	&__text {
		@include wrapper;
		height: calc(100% - 13rem);
		display: flex;
		padding-top: 5rem;

		@media (max-width: $breakPoint769) {
			position: relative;
			z-index: 100;
			padding: 3rem;
		}
	}

	&__text__inner {
		padding: 2rem 0;
		max-width: 49rem;
		p {max-width: 40rem;}
	}


	&__img {
		height: 549px;
		width: 991px;
		object-fit: cover;
		object-position: top;
		@media (max-width: $breakPoint769) {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: cover;
			box-sizing: border-box;
			top: 0;
			left: 0;
			z-index: 0;
		}

		&--small {
			height: 274px;
		}
	}


	// banner
	&__banner {
		width: 44.4rem;
		position: fixed;
		bottom: 24rem;
		right: 0;
		z-index: 1500;


		@media (max-width: $breakPoint769) {
			display: none;
		}

		&__inner {
			position: absolute;
			display: flex;
			align-items: center;
			width: 100%;
			height: 9.3rem;
			background: url("../Static/Img/Frontend/chuck-up-banner-bg.svg") no-repeat;
			background-size: 100%;
			right: -65%;
			transition: right 0.5s ease-in-out;

		}

		img {
			padding: 1em 3em 1em 1.5em;
			height: 100%;
			box-sizing: border-box;
		}

		&:hover, &:active {
			.hero__banner__inner {
				right: 0;
			}
		}

		// left position
		&.hero__banner--left {
			right: auto;
			left: 0;
			top: 76%;

			.hero__banner__inner {
				right: auto;
				left: -65%;
				background-image: url("../Static/Img/Frontend/chuck-up-banner-left-bg.svg");
				transition: left 0.5s ease-in-out;
				justify-content: end;
			}

			img {
				order: 2;
				padding-left: 3em;
				padding-right: 1.5em;
			}

			&:hover, &:active {
				.hero__banner__inner {
					right: auto;
					left: 0;
				}
			}
		}
	}



}

