html {
	font-size: 62.5%;

	@media (max-width: $breakPoint769) {
		font-size: 56%;
	}
}

body {
  font-size: $fontsize-default;
  font-family: "Roboto", sans-serif;
  padding: 0;
  color: #000000;
  background: #ffffff;
  text-align: left;
}

h1,h2 {
  font-weight: $fontweight-700;
	text-transform: uppercase;
}

h1 {
	font-size: $fontsize-41;
	margin: 0 0 0.8em 0;
}

h2 {
  font-size: $fontsize-32;
  margin: 0 0 0.8em 0;
}

h3 {
	font-size: $fontsize-21;
	margin: 0 0 0.8em 0;
}

h4 {
	font-size: $fontsize-14;
	margin: 0 0 0.8em 0;
	background: $color-blue;
	color: #fff;
	padding: 0.429em 0.857em;
	display: inline-block;
}

.no--uppercase {
	text-transform: none;
}

h1,h2,h3 {
	&.subheader {margin-bottom: 0;}
}

header.subheadline {
	display: flex;
	flex-direction: column;
	flex-grow: 0;
	h4 {
		align-self: flex-start;
		order: 0;
	}

	h3 {order: 1;}
}


hr.ce-div {
  border-top: 1px solid #ccc;
  margin: 1em 0 1.25em 0;
  height: 0;
}

// table
table {
  width: 100%;
  margin-bottom: 1em;
  border-collapse: collapse;
}

tr {vertical-align: top;}
td, th {border: none;text-align:left;}

p {
	padding: 0 0 1em 0;margin: 0;
	&:last-child {padding-bottom: 0;}
	&.center {
			max-width: 693px;
			margin: 0 auto;
	}
}

// table - technical
table.technical {
	th {width: 78%;}
	th, td {
		padding: 0.3em 2em 0.3em 0;
	}
}

// links
a {text-decoration: none;outline:none;color: inherit;}
a:hover {color:inherit;}

a.link-external, a.link-download, a.link-mail, a.link-internal, a[href^="tel:"]  {
	font-weight: bold;
	text-decoration: underline;
}

// buttons
a.link-button, .link-button {
  background: $color-orange;
  display: inline-block;
	border-radius: $button-border-radius;
  padding: 0.25em 0.7em;
  color: #fff;
  font-weight: $fontweight-700;
  cursor: pointer;
	@include hovertransition;
	&:hover {
		background: $color-orange-hover;
	}

	@media (max-width: $breakPoint620) {
		padding: 0.7em 0.9em;
	}

	&__inner {
		display: flex;
		gap: 0.7em;
		align-items: center;
	}

	// link button variants
	&.link-button--grey {
		@include hovertransition;
		background: $color-grey;
		&:hover {
			background: $color-grey-hover
		}
	}

	svg {
		width: 0.75em;
		height: 0.75em;
	}

	// link button big
	&.link-button--big {
		padding: 0.75em 1.5em;

		@media (max-width: $breakPoint620) {
				padding: 0.5em 0.75em;
		}

		.link-button__inner {
			gap: 1em;
		}

		svg {
			width: 1.25em;
			height: 1.25em;
		}
	}

	// link button full width
	&.link-button--fullwidth {
		display: block;
		width: 100%;
		box-sizing: border-box;
		text-align: center;
	}

	// link button with flag
	&.link-button--flag {
		background-repeat: no-repeat;
		background-position: left center;
		background-size: contain;
		text-align: left;
		padding-left: 100px;
		@media (max-width: $breakPoint840) {
			padding-left: 87px;
			background-size: 8rem;
		}

		@media (max-width: $breakPoint481) {
			background-size: contain;
		}

	}

	&.link-button--flag--eu {background-image: url("../Static/Icons/Frontend/Flags/eu-flag.svg");}
	&.link-button--flag--us {background-image: url("../Static/Icons/Frontend/Flags/us-flag.svg");}
	&.link-button--flag--ca {background-image: url("../Static/Icons/Frontend/Flags/ca-flag.svg");}
}

// link arrow
.link-arrow {
	background: url("../Static/Icons/Frontend/arrow.svg") no-repeat center right 0.2em;
	padding-right: 2.7em;
	background-size: 1.3em;
	text-decoration: underline;

	@include hovertransition;
	&:hover {
		background-position-x: right;
	}

}

// text formats
em {font-style:italic;}
strong, b {font-weight: $fontweight-700;}

blockquote {
  margin: 0 0 1em 0;
  padding: 0 0 0 1em;
  border-left: 1px solid $color-blue;
  p:first-child {
    &::before {content: "„";}
  }

  p:last-child {
    padding: 0;
    &::after {content: "“"}
  }
}

// lists
ul {
  padding: 0;margin: 0;list-style-type: none;list-style-position: outside;
  li {margin: 0; padding: 0;}
}

ol {
  padding: 0;
  margin: 0;
  list-style: bullet;
//  counter-reset: item;

  li {
    padding: 0 0 0.125em 1.5em;
    position: relative;
    &::before {
      display: inline-block;
      content: counter(item) ". ";
      counter-increment: item;
      width: 2em;
      position: absolute;
      left: 0;
    }
  }
}

main.maincontent {
  ul.bodytext, ol.bodytext {
	  padding-bottom: 0.5em;
	  &:last-child {padding-bottom: 0;}
  }
  ul.bodytext ul, ol.bodytext ol {
	  padding-bottom: 0.25em;
	  &:last-child {padding-bottom: 0;}
  }

  ul.bodytext li {
    padding: 0 0 0.8em 0.8em;
    background: url('../Static/Icons/Frontend/list-icon.svg') left 0.5em no-repeat;
    background-size: 0.2em;
	  &:last-child {padding-bottom: 0;}
  }
}

// bg colors
.bg--lightgrey {
	background-color: $color-lightgrey;
}

// social media
.socialmedia__icon {
  	display: inline-block;
	padding: 0 1em;
	&:first-of-type {padding-left: 0;}

	img {
		height: 3.1rem;
		transition: 0.2s ease-in-out;
		&:hover {
			transform: scale(1.1);
		}
	}


	// small icons
	&.socialmedia__icon--small {
		padding: 0 0.3em;
		&:first-of-type {padding-left: 0;}
		img {
			height: 2rem;
			&:hover {
				transform: scale(1.2);
			}
		}
	}
}

// clear
.clear {
  clear: both;
  line-height: 0;
  height: 0;
  font-size: 0;
}

// responsive css
img, embed, object, video, picture {
  max-width: 100%;
  height: auto;
	vertical-align: bottom;
}

.centered-img {
	// align the image vertically centered inside the grid
	display: grid;
	align-items: center;
}

.center {
	text-align: center;
}

// accessibility
.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.boxfitimg {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}



// colors
.ci-orange {color: $color-orange;}

// element margins & paddings
.paddingbottom--medium {
	padding-bottom: 2em;
}

.paddingbottom--big {
	padding-bottom: 4em;
}

.marginless {margin: 0;}
.paddingless {padding: 0;}
.margin-bottom-big {margin-bottom: 120px;}
