.testimonialbox {
	position: relative;

	@media (max-width: $breakPoint481) {
		padding-bottom: 0;
	}
}

.testimonial__img {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 58%;
	img {
		vertical-align: bottom;
	}

	@media (max-width: $breakPoint481) {
		width: 100%;
	}

	@media (max-width: $breakPoint481) {
		position: relative;
		padding-top: 3rem;
	}
}

.testimonial__stars {
	font-size: $fontsize-32;
	color: $color-orange;

}
