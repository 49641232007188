.mainheader {
	background: $color-blue;
	color: #fff;
	position: relative;
	z-index: 100;
	overflow: hidden;

	@media (max-width: $breakPoint840) {
		font-size: $fontsize-14;
	}


	&__inner {
		@include wrapper;
		display: flex;
		padding-top: 1.6rem;
		padding-bottom: 1.6rem;
		align-items: center;
		gap: 4rem;
		height: 60px;


		@media (max-width: $breakPoint1025) {
			gap: 3rem;
		}

		@media (max-width: $breakPoint769) {
			height: 70px;
		}

		@media (max-width: $breakPoint481) {
			flex-wrap: wrap;
			gap: 2rem;
			height: auto;
		}
	}

	.logo {
		width: 70px;
		flex-shrink: 0;

		@media (max-width: $breakPoint769) {
			position: absolute;
			top: 10px;
			img {width: 50px;}
		}
	}
}



// navigation
nav {
	width: 100%;
	font-weight: $fontweight-500;

	@media (max-width: $breakPoint769) {
		margin-top: 25px;
	}


	ul {
		list-style: none;
		margin: 0;
		display: flex;
		gap: 2rem;
		align-items: center;

		@media (max-width: $breakPoint840) {
			gap: 1.5rem;
		}

		@media (max-width: $breakPoint481) {
			justify-content: space-between;
			gap: 1rem;
			row-gap: 0;
			flex-wrap: wrap;
		}

		li {
			display: block;
			padding: 0;
			margin: 0;

			a {
				// level 1
				&.nav-lv1 {
					display: block;
					color: #fff;
					padding: 0.3em 0;

					@media (max-width: $breakPoint620) {
						padding: 0.8em 0;
					}

				}

			}
		}
	}
}

// language navigation
.nav__language {
	display: flex;
	gap: 6px;

	@media (max-width: $breakPoint769) {
		position: absolute;
		top: 10px;
		right: 0;
		padding-right: inherit;
	}
}

.mainheader--preview {






}

