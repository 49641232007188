.chuckupconfiguratorbox__outer {
	position: relative;
	z-index: 0;

	&.chuckupconfiguratorbox--hero--offset {
		position: relative;
		z-index: 200;
		margin-top: -13rem;

		@media (max-width: $breakPoint769) {
			margin-top: 0;
		}
	}
}

.chuckupconfiguratorbox {
	@include wrapper;

	@media (max-width: $breakPoint769) {
		padding: 0;
	}

	&.chuckupconfiguratorbox--blue {
		&::before {
			content: "";
			position: absolute;
			display: block;
			width: 100%;
			height: 65%;
			top: 0;
			left: 0;
			background: $color-blue;
		}
	}


	&.chuckupconfiguratorbox--bgbottom {
		&::before {
			top: auto;
			bottom: 0;
		}
	}

	&__inner {
		position: relative;
		z-index: 100;
		background: url("../Static/Img/Frontend/Bohrfutter_Finder.webp") 0 0.8rem no-repeat $color-orange;
		background-size: 145px;
		color: #fff;
		padding: 3rem 5.9rem 2.5rem 15.2rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 2.8rem;

		@media (max-width: $breakPoint1025) {
			padding: 3rem 3rem 18rem 3rem;
			background-position: left bottom 1rem;
			background-size: 12rem;
		}

		@media (max-width: $breakPoint481) {
			background-position-y: center;
			padding-bottom: 3rem;
		}

	}

}


.chuckupconfigurator__text {
	text-align: center;
	h2 {margin-bottom: 0;}
	p:last-of-type {
		padding-bottom: 0;
	}
}


// =============
// configurator
// =============
.chuckupconfigurator {
	max-width: 584px;
	width: 100%;

	&__chuck {
		display: none;
	}


	form {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: end;
		gap: 10px;
		@media (max-width: $breakPoint620) {
			justify-content: end;
		}

		@media (max-width: $breakPoint481) {
				justify-content: center;
		}
	}

	.chuckupconfigurator__col--select {
		flex-basis: 210px;
		position: relative;
		@media (max-width: $breakPoint620) {
			flex-basis: 48%;
		}

		@media (max-width: $breakPoint481) {
			flex-basis: 100%;
			padding-left: 12rem;
		}
	}

	label {
		display: block;
		margin-bottom: 0.3em;
	}

	select {
		width: 100%;
		font-size: $fontsize-12;
		padding: 0.33em 0.5em;


		@media (max-width: $breakPoint620) {
			font-size: $fontsize-14;
			padding: 0.7em 0.8em;
		}
	}

	// validation

	.js--chuckupconfigurator__search {
		position: relative;
		cursor: default;

		@media (max-width: $breakPoint481) {
			margin-top: 0.5em;
		}

		&.act {
			background: $color-blue;
			cursor: pointer;
			&:hover {
				background: $color-blue-hover;
			}
		}
	}

	&__info {
		position: absolute;
		bottom: 2.2em;
		right: 0;
		width: 25em;
		background: $color-blue;
		padding: 1em;
		z-index: 100;
		font-weight: $fontweight-400;
		text-align: center;
		opacity: 0;
		pointer-events: none;
		animation: fade-out 0.4s forwards;

		@media (max-width: $breakPoint620) {
			bottom: 3em;
			width: 60vw;
		}

		@media (max-width: $breakPoint481) {
			right: -50%;
		}


		&.chuckupconfigurator__info--show {
			animation: fade-in 0.4s forwards;
		}
	}


}

@keyframes fade-in {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fade-out {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}


// =====================
// configurator - show all button
// =====================
.chuckupconfigurator__showall {
	background: $color-lightgrey;
	padding: 2rem;
	font-weight: $fontweight-700;
}

.toggleswitch {
	display: flex;
	align-items: center;
	justify-content: center;
}

.toggleswitch__text {
	cursor: pointer;
}

.toggleswitch__button {
	position: relative;
	display: inline-block;
	width: 30px;
	height: 17px;
	margin-left: 1.5rem;
}

.toggleswitch__button input {
	opacity: 0;
	width: 0;
	height: 0;
}

.toggleswitch__slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 17px;
}

.toggleswitch__slider:before {
	position: absolute;
	content: "";
	height: 13px;
	width: 13px;
	left: 2px;
	bottom: 2px;
	background-color: $color-grey;
	-webkit-transition: .4s;
	transition: .4s;
	border-radius: 50%;
}

input:checked + .toggleswitch__slider {
	background-color: $color-green;
}

input:checked + .toggleswitch__slider:before {
	-webkit-transform: translateX(13px);
	-ms-transform: translateX(13px);
	transform: translateX(13px);
}

// configurator link button
.chuckup__content__configuratorink {
	display: none;
}

// hide shop buttons if all drill chucks are shown
.chuckupconfigurator__chuck--showall {
	.chuckup__content__configuratorink {display: block;}
	.chuckup__content__shoplinks {display: none;}
}

// =====================
// configurator results
// =====================
.chuckup {

	// chuckup select
	&__select {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}

	&__image {
		padding: 2.4rem;
		display: flex;
		justify-content: center;
		align-items: center;
		opacity: 0.4;
		transform: scale(0.8);
		transition: 500ms;
		&.js--chuckup__model {
			cursor: pointer;
		}

		&.act {
			opacity: 1;
			transform: scale(1);
		}
	}

	&__header {
		background: none;
		color: #000;
		border-top: 1px solid $color-blue;
		border-bottom: 1px solid $color-blue;

		&.js--chuckup__model {
			cursor: pointer;
		}

		&.act {
			background: $color-blue;
			color: #fff;
		}

		h2 {
			padding: 3rem 3.4rem 4rem 3.4rem;
			margin: 0;

			@media (max-width: $breakPoint620) {
				font-size: $fontsize-20;
				padding: 2rem;
			}

			@media (max-width: $breakPoint481) {
				font-size: $fontsize-default;
			}

		}
	}

	&__spindlesize {
		font-size: $fontsize-14;
		background: $color-orange;
		color: #fff;
		display: inline-block;
		padding: 0.3em 0.857em;
		margin-top: -1px;
	}

	// chuckup content
	&__content__outer {
		display: none;
		&.act {
			display: block;
		}
	}

	&__content {
		background: $color-lightgrey;
		padding: 3.2rem;
		margin-bottom: 2rem;
		color: #000;

		h3 {
			margin-bottom: 1.5em;
		}

		@media (max-width: $breakPoint620) {
			padding: 2rem;
		}

		&__shoplinks {

			@media (max-width: $breakPoint620) {
				a.link-button {
					font-size: $fontsize-12;
				}
			}

			@media (max-width: $breakPoint481) {
				padding-top: 2rem;

				a.link-button {
					font-size: $fontsize-default;
				}
			}
		}
	}

	// no results
	&__noresult {
		margin-top: -60px;
		padding-bottom: 30px;
	}
}

// chuckup more
.chuckup__more__item {
	margin-bottom: 3.2rem;
	color: #000;

	.gridcontainer {
		margin-bottom: 40px;
	}

}

.chuckup__more__title {
	background-color: $color-lightgrey;
	padding-top: 2rem;
	padding-bottom: 2rem;
	position: relative;
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: $fontsize-default;
	font-weight: $fontweight-700;

	@media (max-width: $breakPoint620) {
		padding-left: 2rem;
		padding-right: 2rem;
		justify-content: space-between;
	}

	&::before {
		margin: 0 0.7rem 0 0.5rem;
		content: " ";
		display: block;
		width: 2rem;
		height: 2rem;
		background: url("../Static/Icons/Frontend/caret.svg") center center no-repeat;
		background-size: 100%;
		flex-shrink: 0;
		transition: 0.4s;

		@media (max-width: $breakPoint620) {
			order: 1;
			margin: 0;
		}


	}

}

.chuckup__more__content {
	height: 0;
	overflow: hidden;
	transition: height 0.4s ease-out;

	&__inner {
		padding: 3rem 3.2rem;

		@media (max-width: $breakPoint620) {
			padding: 2rem;
		}
	}
}

// active state
.chuckup__more__item.open .chuckup__more__title {
	&::before {transform: rotate(-180deg);}
}

.chuckup__more__property {
	text-transform: uppercase;
	font-weight: $fontweight-700;
}



// chuckupconfigurator results productoverview

.chuckupconfigurator__results--productoverview {
	table.technical th {
		width: 50%;
	}

	.chuckup__content .gridcontainer {
		position: relative;
	}

	// chuckupconfigurator results productoverview - form
	.chuckup__sizeoptin {
		position: absolute;
		left: 50%;
		top: 0;
		display: flex;
		align-items: center;
		font-weight: $fontweight-700;

		@media (max-width: $breakPoint481) {
			position: static;
			margin-bottom: 2rem;
		}

		&__checkbox__label {
			width: 1.6rem;
			height: 1.6rem;
			outline: 2px solid $color-orange;
			display: block;
			flex-shrink: 0;
			margin-right: 1.3rem;
			box-sizing: border-box;
		}

		input {display: none;}
		input:checked + .chuckup__sizeoptin__checkbox__label {
			position: relative;
			&::after {
				content: "";
				position: absolute;
				display: block;
				width: 1.2rem;
				height: 1.2rem;
				top: 0.2rem;
				left: 0.2rem;
				background: $color-orange;
			}
		}
	}


	.chuckup__content__shoplinks {
		margin-top: 6.2rem;

		@media (max-width: $breakPoint481) {
			margin-top: 3rem;
		}
	}

	// shop links
	.chuckup__content__shoplinks--disabled {

		.link-button {
			pointer-events: none;
			background-color: $color-lightgrey;
			box-shadow: inset 0 0 0 1000px rgba(0,0,0,0.2);
		}
	}
}

// loading indicator
.chuckupconfigurator__loading {
	position: absolute;
	top: 51px;
	font-size: $fontsize-12;
	padding: 0.33em 0.5em;

	@media (max-width: $breakPoint769) {
		top: 45px;
	}

	@media (max-width: $breakPoint620) {
		top: 57px;
	}

	@media (max-width: $breakPoint481) {
		position: static;
		padding-bottom: 0.8em;
	}
}
