.glightbox-container {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999 !important;
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;
	-webkit-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	outline: none;
}

.glightbox-container.inactive {
	display: none;
}

.glightbox-container .gcontainer {
	position: relative;
	width: 100%;
	height: 100%;
	z-index: 9999;
	overflow: hidden;
}

.glightbox-container .gslider {
	-webkit-transition: -webkit-transform 0.4s ease;
	transition: -webkit-transform 0.4s ease;
	transition: transform 0.4s ease;
	transition: transform 0.4s ease, -webkit-transform 0.4s ease;
	height: 100%;
	left: 0;
	top: 0;
	width: 100%;
	position: relative;
	overflow: hidden;
	display: -webkit-box !important;
	display: -ms-flexbox !important;
	display: flex !important;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.glightbox-container .gslide {
	width: 100%;
	position: absolute;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	opacity: 0;
}

.glightbox-container .gslide.current {
	opacity: 1;
	z-index: 99999;
	position: relative;
}

.glightbox-container .gslide.prev {
	opacity: 1;
	z-index: 9999;
}

.glightbox-container .gslide-inner-content {
	width: 100%;
}

.glightbox-container .ginner-container {
	position: relative;
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	max-width: 100%;
	margin: auto;
	height: 100vh;
}

.glightbox-container .ginner-container.gvideo-container {
	width: 100%;
}

.glightbox-container .ginner-container.desc-bottom,
.glightbox-container .ginner-container.desc-top {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.glightbox-container .ginner-container.desc-left,
.glightbox-container .ginner-container.desc-right {
	max-width: 100% !important;
}

.gslide iframe,
.gslide video {
	outline: none !important;
	border: none;
	min-height: 165px;
	-webkit-overflow-scrolling: touch;
	-ms-touch-action: auto;
	touch-action: auto;
}

.gslide:not(.current) {
	pointer-events: none;
}

.gslide-image {
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

.gslide-image img {
	max-height: 100vh;
	display: block;
	padding: 0;
	float: none;
	outline: none;
	border: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	max-width: 100vw;
	width: auto;
	height: auto;
	-o-object-fit: cover;
	object-fit: cover;
	-ms-touch-action: none;
	touch-action: none;
	margin: auto;
	min-width: 200px;
}

.desc-top .gslide-image img,
.desc-bottom .gslide-image img {
	width: auto;
}

.desc-left .gslide-image img,
.desc-right .gslide-image img {
	width: auto;
	max-width: 100%;
}

.gslide-image img.zoomable {
	position: relative;
}

.gslide-image img.dragging {
	cursor: -webkit-grabbing !important;
	cursor: grabbing !important;
	-webkit-transition: none;
	transition: none;
}

.gslide-video {
	position: relative;
	max-width: 100vh;
	width: 100% !important;
}

.gslide-video .plyr__poster-enabled.plyr--loading .plyr__poster {
	display: none;
}

.gslide-video .gvideo-wrapper {
	width: 100%;
	/* max-width: 160vmin; */
	margin: auto;
}

.gslide-video::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
	background: rgba(255, 0, 0, 0.34);
	display: none;
}

.gslide-video.playing::before {
	display: none;
}

.gslide-video.fullscreen {
	max-width: 100% !important;
	min-width: 100%;
	height: 75vh;
}

.gslide-video.fullscreen video {
	max-width: 100% !important;
	width: 100% !important;
}

.gslide-inline {
	background: #fff;
	text-align: left;
	max-height: calc(100vh - 40px);
	overflow: auto;
	max-width: 100%;
	margin: auto;
}

.gslide-inline .ginlined-content {
	padding: 20px;
	width: 100%;
}

.gslide-inline .dragging {
	cursor: -webkit-grabbing !important;
	cursor: grabbing !important;
	-webkit-transition: none;
	transition: none;
}

.ginlined-content {
	overflow: auto;
	display: block !important;
	opacity: 1;
}

.gslide-external {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	min-width: 100%;
	background: #fff;
	padding: 0;
	overflow: auto;
	max-height: 75vh;
	height: 100%;
}

.gslide-media {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: auto;
}

.zoomed .gslide-media {
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

.desc-top .gslide-media,
.desc-bottom .gslide-media {
	margin: 0 auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.gslide-description {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 0 100%;
	flex: 1 0 100%;
}

.gslide-description.description-left,
.gslide-description.description-right {
	max-width: 100%;
}

.gslide-description.description-bottom,
.gslide-description.description-top {
	margin: 0 auto;
	width: 100%;
}

.gslide-description p {
	margin-bottom: 12px;
}

.gslide-description p:last-child {
	margin-bottom: 0;
}

.zoomed .gslide-description {
	display: none;
}

.glightbox-button-hidden {
	display: none;
}


/*
 * Description for mobiles
 * something like facebook does the description
 * for the photos
*/

.glightbox-mobile .glightbox-container .gslide-description {
	height: auto !important;
	width: 100%;
	position: absolute;
	bottom: 0;
	padding: 19px 11px;
	max-width: 100vw !important;
	-webkit-box-ordinal-group: 3 !important;
	-ms-flex-order: 2 !important;
	order: 2 !important;
	max-height: 78vh;
	overflow: auto !important;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.75)));
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
	-webkit-transition: opacity 0.3s linear;
	transition: opacity 0.3s linear;
	padding-bottom: 50px;
}

.glightbox-mobile .glightbox-container .gslide-title {
	color: #fff;
	font-size: 1em;
}

.glightbox-mobile .glightbox-container .gslide-desc {
	color: #a1a1a1;
}

.glightbox-mobile .glightbox-container .gslide-desc a {
	color: #fff;
	font-weight: bold;
}

.glightbox-mobile .glightbox-container .gslide-desc * {
	color: inherit;
}

.glightbox-mobile .glightbox-container .gslide-desc .desc-more {
	color: #fff;
	opacity: 0.4;
}

.gdesc-open .gslide-media {
	-webkit-transition: opacity 0.5s ease;
	transition: opacity 0.5s ease;
	opacity: 0.4;
}

.gdesc-open .gdesc-inner {
	padding-bottom: 30px;
}

.gdesc-closed .gslide-media {
	-webkit-transition: opacity 0.5s ease;
	transition: opacity 0.5s ease;
	opacity: 1;
}

.greset {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

.gabsolute {
	position: absolute;
}

.grelative {
	position: relative;
}

.glightbox-desc {
	display: none !important;
}

.glightbox-open {
	overflow: hidden;
}

.gloader {
	height: 25px;
	width: 25px;
	-webkit-animation: lightboxLoader 0.8s infinite linear;
	animation: lightboxLoader 0.8s infinite linear;
	border: 2px solid #fff;
	border-right-color: transparent;
	border-radius: 50%;
	position: absolute;
	display: block;
	z-index: 9999;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: 47%;
}

.goverlay {
	width: 100%;
	height: calc(100vh + 1px);
	position: fixed;
	top: -1px;
	left: 0;
	background: #000;
	will-change: opacity;
}

.glightbox-mobile .goverlay {
	background: #000;
}

.gprev,
.gnext,
.gclose {
	z-index: 99999;
	cursor: pointer;
	width: 26px;
	height: 44px;
	border: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

.gprev svg,
.gnext svg,
.gclose svg {
	display: block;
	width: 25px;
	height: auto;
	margin: 0;
	padding: 0;
}

.gprev.disabled,
.gnext.disabled,
.gclose.disabled {
	opacity: 0.1;
}

.gprev .garrow,
.gnext .garrow,
.gclose .garrow {
	stroke: #fff;
}

.gbtn.focused {
	outline: 2px solid #0f3d81;
}

iframe.wait-autoplay {
	opacity: 0;
}

.glightbox-closing .gnext,
.glightbox-closing .gprev,
.glightbox-closing .gclose {
	opacity: 0 !important;
}


/*Skin */

.glightbox-clean .gslide-description {
	background: #fff;
}

.glightbox-clean .gdesc-inner {
	padding: 22px 20px;
}

.glightbox-clean .gslide-title {
	font-size: 1em;
	font-weight: normal;
	font-family: arial;
	color: #000;
	margin-bottom: 19px;
	line-height: 1.4em;
}

.glightbox-clean .gslide-desc {
	font-size: 0.86em;
	margin-bottom: 0;
	font-family: arial;
	line-height: 1.4em;
}

.glightbox-clean .gslide-video {
	background: #000;
}

.glightbox-clean .gprev,
.glightbox-clean .gnext,
.glightbox-clean .gclose {
	background-color: rgba(0, 0, 0, 0.75);
	border-radius: $button-border-radius;
}

.glightbox-clean .gprev path,
.glightbox-clean .gnext path,
.glightbox-clean .gclose path {
	fill: #fff;
}

.glightbox-clean .gprev {
	position: absolute;
	top: -100%;
	left: 30px;
	width: 40px;
	height: 50px;
}

.glightbox-clean .gnext {
	position: absolute;
	top: -100%;
	right: 30px;
	width: 40px;
	height: 50px;
}

.glightbox-clean .gclose {
	width: 35px;
	height: 35px;
	top: 15px;
	right: 10px;
	position: absolute;
}

.glightbox-clean .gclose svg {
	width: 18px;
	height: auto;
}

.glightbox-clean .gclose:hover {
	opacity: 1;
}


/*CSS Animations*/

.gfadeIn {
	-webkit-animation: gfadeIn 0.5s ease;
	animation: gfadeIn 0.5s ease;
}

.gfadeOut {
	-webkit-animation: gfadeOut 0.5s ease;
	animation: gfadeOut 0.5s ease;
}

.gslideOutLeft {
	-webkit-animation: gslideOutLeft 0.3s ease;
	animation: gslideOutLeft 0.3s ease;
}

.gslideInLeft {
	-webkit-animation: gslideInLeft 0.3s ease;
	animation: gslideInLeft 0.3s ease;
}

.gslideOutRight {
	-webkit-animation: gslideOutRight 0.3s ease;
	animation: gslideOutRight 0.3s ease;
}

.gslideInRight {
	-webkit-animation: gslideInRight 0.3s ease;
	animation: gslideInRight 0.3s ease;
}

.gzoomIn {
	-webkit-animation: gzoomIn 0.5s ease;
	animation: gzoomIn 0.5s ease;
}

.gzoomOut {
	-webkit-animation: gzoomOut 0.5s ease;
	animation: gzoomOut 0.5s ease;
}

@-webkit-keyframes lightboxLoader {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes lightboxLoader {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes gfadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes gfadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@-webkit-keyframes gfadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes gfadeOut {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@-webkit-keyframes gslideInLeft {
	from {
		opacity: 0;
		-webkit-transform: translate3d(-60%, 0, 0);
		transform: translate3d(-60%, 0, 0);
	}
	to {
		visibility: visible;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@keyframes gslideInLeft {
	from {
		opacity: 0;
		-webkit-transform: translate3d(-60%, 0, 0);
		transform: translate3d(-60%, 0, 0);
	}
	to {
		visibility: visible;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@-webkit-keyframes gslideOutLeft {
	from {
		opacity: 1;
		visibility: visible;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	to {
		-webkit-transform: translate3d(-60%, 0, 0);
		transform: translate3d(-60%, 0, 0);
		opacity: 0;
		visibility: hidden;
	}
}

@keyframes gslideOutLeft {
	from {
		opacity: 1;
		visibility: visible;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	to {
		-webkit-transform: translate3d(-60%, 0, 0);
		transform: translate3d(-60%, 0, 0);
		opacity: 0;
		visibility: hidden;
	}
}

@-webkit-keyframes gslideInRight {
	from {
		opacity: 0;
		visibility: visible;
		-webkit-transform: translate3d(60%, 0, 0);
		transform: translate3d(60%, 0, 0);
	}
	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@keyframes gslideInRight {
	from {
		opacity: 0;
		visibility: visible;
		-webkit-transform: translate3d(60%, 0, 0);
		transform: translate3d(60%, 0, 0);
	}
	to {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@-webkit-keyframes gslideOutRight {
	from {
		opacity: 1;
		visibility: visible;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	to {
		-webkit-transform: translate3d(60%, 0, 0);
		transform: translate3d(60%, 0, 0);
		opacity: 0;
	}
}

@keyframes gslideOutRight {
	from {
		opacity: 1;
		visibility: visible;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	to {
		-webkit-transform: translate3d(60%, 0, 0);
		transform: translate3d(60%, 0, 0);
		opacity: 0;
	}
}

@-webkit-keyframes gzoomIn {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
	to {
		opacity: 1;
	}
}

@keyframes gzoomIn {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
	to {
		opacity: 1;
	}
}

@-webkit-keyframes gzoomOut {
	from {
		opacity: 1;
	}
	50% {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
	to {
		opacity: 0;
	}
}

@keyframes gzoomOut {
	from {
		opacity: 1;
	}
	50% {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
	to {
		opacity: 0;
	}
}

@media (min-width: 769px) {
	.glightbox-container .ginner-container {
		width: auto;
		height: auto;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.glightbox-container .ginner-container.desc-top .gslide-description {
		-webkit-box-ordinal-group: 1;
		-ms-flex-order: 0;
		order: 0;
	}
	.glightbox-container .ginner-container.desc-top .gslide-image,
	.glightbox-container .ginner-container.desc-top .gslide-image img {
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1;
	}
	.glightbox-container .ginner-container.desc-left .gslide-description {
		-webkit-box-ordinal-group: 1;
		-ms-flex-order: 0;
		order: 0;
	}
	.glightbox-container .ginner-container.desc-left .gslide-image {
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1;
	}
	.gslide-image img {
		max-height: 97vh;
		max-width: 100%;
	}
	.gslide-image img.zoomable {
		cursor: -webkit-zoom-in;
		cursor: zoom-in;
	}
	.zoomed .gslide-image img.zoomable {
		cursor: -webkit-grab;
		cursor: grab;
	}
	.gslide-inline {
		max-height: 95vh;
	}
	.gslide-external {
		max-height: 100vh;
	}
	.gslide-description.description-left,
	.gslide-description.description-right {
		max-width: 275px;
	}
	.glightbox-open {
		height: auto;
	}
	.goverlay {
		background: rgba(0, 0, 0, 0.92);
	}
	.glightbox-clean .gslide-media {
		-webkit-box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
		box-shadow: 1px 2px 9px 0px rgba(0, 0, 0, 0.65);
	}
	.glightbox-clean .description-left .gdesc-inner,
	.glightbox-clean .description-right .gdesc-inner {
		position: absolute;
		height: 100%;
		overflow-y: auto;
	}
	.glightbox-clean .gprev,
	.glightbox-clean .gnext,
	.glightbox-clean .gclose {
		background-color: rgba(0, 0, 0, 0.32);
	}
	.glightbox-clean .gprev:hover,
	.glightbox-clean .gnext:hover,
	.glightbox-clean .gclose:hover {
		background-color: rgba(0, 0, 0, 0.7);
	}
	.glightbox-clean .gprev {
		top: 45%;
	}
	.glightbox-clean .gnext {
		top: 45%;
	}
}

@media (min-width: 992px) {
	.glightbox-clean .gclose {
		opacity: 0.7;
		right: 20px;
	}
}

@media screen and (max-height: 420px) {
	.goverlay {
		background: #000;
	}
}
