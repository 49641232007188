.faq__item {
	background-color: $color-lightgrey;
	margin-bottom: 3.2rem;
	padding: 2.4rem 3.2rem;
	color: #000;

	@media (max-width: $breakPoint481) {
		padding: 2rem;
	}
}

.faq__question {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
	font-size: $fontsize-20;
	font-weight: $fontweight-700;

	&::after {
		margin-left: 1em;
		content: " ";
		display: block;
		width: 1.5em;
		height: 1.5em;
		background: url("../Static/Icons/Frontend/caret.svg") center center no-repeat;
		background-size: 100%;
		flex-shrink: 0;
		transition: 0.4s;
	}

}

.faq__answer {
	font-size: $fontsize-12;
	height: 0;
	overflow: hidden;
	transition: height 0.4s ease-out;

	@media (max-width: $breakPoint481) {
		font-size: $fontsize-default;
	}

	&__inner {
		padding-top: 3rem;
	}
}

// active state
.faq__item.open .faq__question {
	&::after {transform: rotate(-180deg);}
}


