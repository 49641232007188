.maincontent {
	color: $color-blue;
}

.contentsection__outer--blue {
	background: $color-blue;
	color: #fff;
}

.contentsection {
	@include wrapper;
	padding-top: 4rem;
	padding-bottom: 4rem;
	&.paddingless {padding-bottom: 0;}
}
