// @import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&subset=cyrillic');

// roboto-regular 400 - cyrillic-ext_latin_latin-ext
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
	src: url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
	url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
	url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

// roboto-500 - cyrillic-ext_latin_latin-ext
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-500.eot'); /* IE9 Compat Modes */
	src: url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-500.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
	url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-500.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
	url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

// roboto-700 - cyrillic-ext_latin_latin-ext
@font-face {
	font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-700.eot'); /* IE9 Compat Modes */
	src: url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
	url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
	url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
	url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-700.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
	url('../Static/Fonts/roboto-v30-cyrillic-ext_latin_latin-ext-700.svg#Roboto') format('svg'); /* Legacy iOS */
}


