.benefits {
	&__item {
		display: grid;
		grid-template-columns: 4fr 8fr;
		gap: 2rem;
		margin-bottom: 3rem;

		@media (max-width: $breakPoint620) {
			grid-template-columns: 1fr;
			gap: 1.4rem;
		}
	}
	&__icon {
		display: flex;
		font-weight: $fontweight-700;
		gap: 3.1rem;
		align-items: flex-start;

		@media (max-width: $breakPoint620) {
			gap: 2rem;
		}

		img {
			width: 2.5rem;
		}
	}
}

main.maincontent .benefits ul.bodytext li {
	padding-bottom: 0.2em;
}
