.gridcontainer {
	display: grid;
	grid-gap: $defaultgridgap;
	margin-bottom: $defaultgridgap;
	grid-template-columns: repeat(12, 1fr);
	&.marginless {margin: 0;}
}

.gridcontainer--cols-1 {
	grid-column: span 1;
}

.gridcontainer--cols-2 {
	grid-column: span 2;
}

.gridcontainer--cols-3 {
	grid-column: span 3;
}

.gridcontainer--cols-4 {
	grid-column: span 4;
}

.gridcontainer--cols-5 {
	grid-column: span 5;
}

.gridcontainer--cols-6 {
	grid-column: span 6;
}

.gridcontainer--cols-7 {
	grid-column: span 7;
}

.gridcontainer--cols-8 {
	grid-column: span 8;
}

.gridcontainer--cols-9 {
	grid-column: span 9;
}

.gridcontainer--cols-10 {
	grid-column: span 10;
}

.gridcontainer--cols-11 {
	grid-column: span 11;
}

.gridcontainer--cols-12 {
	grid-column: span 12;
}

@media (max-width: $breakPoint481) {
	.gridcontainer--cols-1, .gridcontainer--cols-2, .gridcontainer--cols-3, .gridcontainer--cols-4, .gridcontainer--cols-5, .gridcontainer--cols-6, .gridcontainer--cols-7, .gridcontainer--cols-8, .gridcontainer--cols-9, .gridcontainer--cols-10, .gridcontainer--cols-11, .gridcontainer--cols-12 {
		grid-column: span 12;
	}
}

// gridcontainer revert order
.gridcontainer--revertorder {
	@media (max-width: $breakPoint481) {
		& > div:nth-child(1) {
			order: 2;
		}
		& > div:nth-child(2) {
			order: 1;
		}
	}
}

// flexcontainer
.flexcontainer {
	display: flex;
	gap: $defaultgridgap;
}
