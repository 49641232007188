.instruction {
	color: #000;

	&__step {
		display: none;

		&.act {display: block;}
	}

	&__pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 2.7rem;
		padding: 1rem 0;
		&__link.disabled {
			opacity: 0.5;
			pointer-events: none;
		}
	}
}

// instruction variants
.instruction__variant {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	margin-bottom: 12rem;
	&.act {
		@include actoutline;
	}

	&__img {
		width: 100%;
	}
	&__icon {
		background: $color-blue;
		height: 3.5rem;
		padding: 1.9rem;
		margin-bottom: 1.8rem;
		border-radius: $box-border-radius;
	}

	&__content {
		padding: 0 3rem 3rem 3rem;
		position: relative;
		top: -3.65rem;
		h2 {margin-bottom: 1.5rem;}
	}
}


// instruction infobox
.instruction__infobox__outer {
	margin-bottom: 4.5rem;
}

.instruction__infobox {
	h2 {margin-bottom: 1.5rem;}
	color: #fff;
	background: $color-blue;
	padding: 3rem 3.5rem;
	margin-bottom: 4.5rem;
	&.marginless {margin-bottom: 0;}
}

// instruction subvariant (video or step-by-step)
.instruction__subvariant {
	margin-bottom: 12rem;

	&__img {
		position: relative;
		img {width: 100%;}
		margin-bottom: 2.8rem;
		cursor: pointer;
	}

	&__header {
		position: absolute;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 100;
		top: 0;
		width: 100%;
		height: 100%;
		color: #fff;
		font-size: $fontsize-32;
		font-weight: $fontweight-700;
		text-transform: uppercase;
	}

	// act state
	&.act {
		.instruction__subvariant__img {
			@include actoutline;
		}
	}
}

// instruction toolbox
.instruction__toolbox {
	h3 {margin-bottom: 0.4em;}

	color: #000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	&__inner {
		border: 1px solid #000;
		padding: 2.5rem 2rem 2.5rem 2rem;
	}


}


// instruction steps main selection
.instruction__content {
	display: none;
	&.act {display: block;}
}

// instruction steps

.instruction__subvariant__content {
	display: none;
	&.act {display: block;}
	.gridcontainer {
		margin-bottom: 6.5rem;
	}
}

.instruction__step__header {
	font-size: $fontsize-20;
	font-weight: $fontweight-700;
	color: $color-orange;
	margin-bottom: 3rem;
}

// text icons
.texticon {
	display: block;
	padding: 1.7rem 0;
	clear: right;
	img {
		float: right;
		padding-left: 0.8rem;
	}
}

// spindle stop results
.spindlestop__result {
	&__img {
		position: relative;
		margin-bottom: 1.5rem;
		display: block;

		.spindlestop__result__icon {
			position: absolute;
			bottom: 2rem;
			right: 2rem;
			width: 6rem;
		}
	}

	img {
		width: 100%;
		cursor: pointer;
		&.act {
			@include actoutline;
		}
	}
	&__header {
		font-size: $fontsize-21;
		font-weight: $fontweight-700;
		margin-bottom: 1.5rem;
	}
}

// instruction assembly
.instruction__assemby__content {
	display: none;

	&.act {
		display: block;
	}
}

.selectbox__content {
	display: none;
	&.act {display: block;}
}


// selection
.instruction__select {

	&__text {
		font-size: $fontsize-21;
		font-weight: $fontweight-700;
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}

	&__item {
		cursor: pointer;
		margin-bottom: 3rem;
	}

	// act state
	&__item.act {
		.instruction__select__img {
			outline: 4px solid $color-orange;
		}

		.instruction__select__text, p {
			color: $color-orange;
		}
	}

	// result
	&__result {
		display: none;
		&.act, .chuckupconfigurator__chuck {
			display: block;
		}
	}
}

// to top arrow
.top__arrow {
	position: fixed;
	right: 0;
	bottom: 3rem;
	background: $color-orange-75;
	padding: 1.1rem 1rem;
	box-sizing: border-box;
	width: 111px;
	cursor: pointer;
}

// add fadein animation for the top arrow
.top__arrow {
	opacity: 0;
	visibility: hidden;
	animation: fadeout 0.5s;
	&.act {
		animation: fadein 0.5s;
		animation-fill-mode: forwards;
	}
}


@keyframes fadein {
	from {
		visibility: visible;
		opacity: 0;
	}

	to {
		opacity: 1;
		visibility: visible;
	}
}

@keyframes fadeout {
	0% {
		opacity: 1;
		visibility: visible;
	}
	100% {
		opacity: 0;
		visibility: hidden;
	}
}
